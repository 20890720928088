import { Injectable, inject } from '@angular/core';
import { EnvironmentService } from '@big-direkt/utils/environment';
import { MeiliSearch, type SearchResponse } from 'meilisearch';
import { type HomeopathyDoctorSearchParams } from '../models/homeopathy-doctor-search-params.model';
import { type HomeopathyDoctorSearchResultModel } from '../models/homeopathy-doctor-search-result.model';
import { type HomeopathyDoctorSearchResultResource } from '../models/homeopathy-doctor-search-result.resource';

@Injectable({
    providedIn: 'root',
})
export class HomeopathyDoctorSearchService {
    private readonly stIndex = 'st_homeopathy_search';

    private readonly environment = inject(EnvironmentService);
    private readonly client;
    private readonly defaultDoctorControllerLimit = 10;

    public constructor() {
        this.client = new MeiliSearch({
            host: this.environment.baseHref,
        });
    }

    public async getMedicalFields(): Promise<string[]> {
        const response = await this.client.index(this.stIndex).search(undefined, {
            facets: ['FACH'],
            limit: 0,
        });

        if (!response.facetDistribution) {
            return [];
        }

        return Object.keys(response.facetDistribution.FACH);
    }

    public async getTechnicalTerms(medicalField: string): Promise<string[]> {
        const response = await this.client.index(this.stIndex).search(undefined, {
            filter: `FACH = "${medicalField}"`,
            facets: ['FACHBEZEIC'],
            limit: 0,
        });

        if (!response.facetDistribution) {
            return [];
        }

        return Object.keys(response.facetDistribution.FACHBEZEIC);
    }

    public async getResults(params: HomeopathyDoctorSearchParams): Promise<HomeopathyDoctorSearchResultModel> {
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        const filter = [`_geoRadius(${params.geodistanceParams.geo.lat}, ${params.geodistanceParams.geo.lng}, ${params.geodistanceParams.radius * 1000})`];
        if (params.medicalField) {
            filter.push(`FACH = "${params.medicalField}"`);
            if (params.technicalTerm) {
                filter.push(`FACHBEZEIC = "${params.technicalTerm}"`);
            }
        }
        const response: SearchResponse<HomeopathyDoctorSearchResultResource> = await this.client.index(this.stIndex).search(params.queryString, {
            filter,
            hitsPerPage: this.defaultDoctorControllerLimit,
            page: params.page,
            sort: [`_geoPoint(${params.geodistanceParams.geo.lat}, ${params.geodistanceParams.geo.lng}):asc`],
        });

        return {
            total: response.totalHits ?? 0,
            doctors: response.hits.map(hit => ({
                title: hit.KS_TITEL,
                firstname: hit.KS_VORNAME,
                lastname: hit.KS_NAME,
                street: hit.KS_POSTSTR,
                zip: hit.KS_POSTPLZ,
                city: hit.KS_POSTORT,
                phone: hit.KS_TELEFON,
            })),
        };
    }
}
